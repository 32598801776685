import Content from '../content/';
const content = new Content();

export default class Locations {

  constructor() {
    this.data = [{
        id: "A0",
        type: "image",
        title: "Brilliance. in color.",
        src: "A0.png",
        buttons: [{
          title: 'DYNEO™',
          icon: "",
          pos: {
            x: 42,
            y: 97
          },
          onclick: () => {
            content.show('brilliance_color')
          }
        }],
        destinations: [{
            target: "A1",
            video: "A",
            start: 0,
            stop: 7,
            name: "Zu A1",
            position: "center-left"
          },
          {
            target: "A3",
            video: "A_reverse",
            start: 0,
            stop: 6,
            name: "Zu A3",
            position: "center-right"
          }
        ]
      },
      {
        id: "A1",
        type: "image",
        title: "Connection. Easy.",
        src: "A1.png",
        buttons: [{
          title: 'Pump connections',
          icon: "",
          pos: {
            x: 61,
            y: 66
          },
          onclick: () => {
            content.show('connection_easy')
          }
        }],
        destinations: [{
            target: "A2",
            video: "A",
            start: 7,
            stop: 14,
            name: "Zu A2",
            position: "center-left"
          },
          {
            target: "A0",
            video: "A_reverse",
            start: 20.5,
            stop: 26.5,
            name: "Zu A0",
            position: "center-right"
          }
        ]
      },
      {
        id: "A2",
        type: "image",
        title: "Internal. External.",
        src: "A2.png",
        buttons: [{
          title: 'Easy switch',
          icon: "",
          pos: {
            x: 45.5,
            y: 62.5
          },
          onclick: () => {
            content.show('internal_external')
          }
        }],
        destinations: [{
            target: "A3",
            video: "A",
            start: 14,
            stop: 21,
            name: "Zu A2",
            position: "center-left"
          },
          {
            target: "A1",
            video: "A_reverse",
            start: 13.5,
            stop: 20,
            name: "Zu A1",
            position: "center-right"
          }
        ]
      },
      {
        id: "A3",
        type: "image",
        title: "External Connections.",
        src: "A3.png",
        buttons: [{
          title: 'Range of external',
          icon: "",
          pos: {
            x: 63.7,
            y: 86
          },
          onclick: () => {
            content.show('external_connections')
          }
        }],
        destinations: [{
            target: "A0",
            video: "A",
            start: 21,
            stop: 27,
            name: "Zu A3",
            position: "center-left"
          },
          {
            target: "A2",
            video: "A_reverse",
            start: 6.5,
            stop: 13,
            name: "Zu A2",
            position: "center-right"
          }
        ]
      }
    ]

  }

  getAll() {
    return this.data;
  }

}