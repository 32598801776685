import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

import "./index.scss";

import Locations from "./modules/locations/";
import Content from "./modules/content/";
import VideoThing from "videoThing";
import VideoThingMenu from "./modules/videoThingMenu/";
// import appData from "./appData.json";

UIkit.use(Icons);

UIkit.switcher(document.querySelectorAll(".uk-switcher"));

const content = new Content();
const locations = new Locations();
const vt = document.querySelector(".app__vt");
const footer = document.querySelector(".app__footer");

// content.show('digital_reality')

const settings = {
  assetPath: "./static/vt",
  startLocation: "A0",
  preloadDestinationsAtStart: false,
  screensaver: {
    timeout: 20,
    cue: ["A1", "A2", "A3", "A0"],
    locationTime: 8
  },
  buttons: {
    staggerVal: 200,
    transitionDuration: 400
  },
  controls: {
    show: true,
    transitionDuration: 800
  },
  transitionOverlay: {
    color: "#ffffff",
    transitionDuration: 1000
  }
};

const videoThing = new VideoThing(vt, locations.getAll(), settings);

videoThing.on("location_reached", id => {
  console.info("You reached location: " + id);
});

videoThing.on("location_left", id => {
  console.info("You left location: " + id);
  content.hide();
});

window.onload = () => {
  document.querySelectorAll(".initally-hidden").forEach(elem => {
    elem.classList.remove("initally-hidden");
  });

  document.querySelector(".loader").classList.add("loader--hidden");
};

// Desktop-Menu (dropdown)
var menua = new VideoThingMenu(
  videoThing,
  {
    locations: locations,
    content: content
  },
  "dropdown",
  () => {
    document.querySelector(".vt-dropdown-menu").appendChild(menua.getElem());
  }
);

// Mobile-Menu (tabs)
var menub = new VideoThingMenu(
  videoThing,
  {
    locations: locations,
    content: content
  },
  "tabs",
  () => {
    document.querySelector(".vt-tabs-menu").appendChild(menub.getElem());
  }
);
