import htmlToElement from "html-to-element";

export default class VideoThingMenu {
  constructor(videoThing, data, style = "dropdown", cb) {
    this.videoThing = videoThing;
    this.data = data;

    data.content.getAll().then(contentEntries => {
      console.log(contentEntries);

      this.elem = htmlToElement(
        require(`./index-${style}.twig`)({
          contents: contentEntries,
          locations: data.locations.getAll()
        })
      );

      this.bindEvents();
      cb();
    });
  }

  getElem() {
    return this.elem;
  }

  bindEvents() {
    this.elem.querySelectorAll("[data-location]").forEach(elem => {
      elem.addEventListener("click", () => {
        let locationId = elem.dataset.location;
        this.videoThing.goToLocation(locationId);
      });

      this.videoThing.on("location_reached", id => {
        this.setCurrentLocation(id);
      });

      this.videoThing.on("location_left", id => {
        this.unsetCurrentLocation();
      });
    });

    this.elem.querySelectorAll("[data-content]").forEach(elem => {
      elem.addEventListener("click", () => {
        let contentId = elem.dataset.content;
        this.data.content.show(contentId);
      });
    });
  }

  unsetCurrentLocation() {
    this.elem.querySelectorAll("[data-location]").forEach(elem => {
      elem.parentNode.classList.remove("uk-active");
    });
  }

  setCurrentLocation(id) {
    this.elem.querySelectorAll('[data-location="' + id + '"]').forEach(elem => {
      elem.parentNode.classList.add("uk-active");
    });
  }
}
