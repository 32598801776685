import htmlToElement from "html-to-element";
import UIkit from "uikit";
import "./index.scss";

export default class Content {
  constructor() {}

  getAll() {
    return new Promise((resolve, reject) => {
      fetch("./static/content/content.json").then(response => {
        resolve(response.json());
      });
    });
  }

  getDataById(id) {
    return new Promise((resolve, reject) => {
      fetch("./static/content/content.json")
        .then(response => {
          return response.json();
        })
        .then(all => {
          var res = all.find(elem => {
            return elem.id == id;
          });

          resolve(res);
        });
    });
  }

  async show(id) {
    var data = await this.getDataById(id);

    data.imageSlides = data.slides.filter(slide => {
      return slide.type == "image";
    });

    if (data.imageSlides.length == 0) {
      data.imageSlides.push({
        type: "image",
        src: "./static/content/placeholder.jpg",
        title: "Virtual Reality",
        subtitle: "Mach dir die Welt, wie sie dir gefällt.",
        html:
          "<p><strong>Informatives Entertainment für Ihren Messeauftritt oder Ihr Event!</strong></p><p>Lassen Sie Ihre Kunden in eine einzigartige 360° Ansicht eintauchen und nutzen Sie die uneingeschränkte Aufmerksamkeit Ihrer Besucher zur effektiven Produktpräsentation.</p><p>VR eignet sich hervorragend für digitale Showrooms, Gamification, Gebäude-Rundgänge sowie Previews aus den Bereichen Architektur und Messebau.</p><p>Wir beraten Sie gerne zu Technik, Inhalt und Inszenierung!</p>"
      });
    }

    if (window.innerWidth <= 960) {
      this.elem = htmlToElement(require("./index.twig")(data));
    } else {
      this.elem = htmlToElement(require("./index-desktop.twig")(data));
    }

    UIkit.modal(this.elem).show();

    UIkit.util.on(this.elem, "hidden", () => {
      // delete elem from dom
      this.elem.parentNode.removeChild(this.elem);
    });
  }

  hide() {
    UIkit.modal(this.elem).hide();
  }
}
